import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import parse from "html-react-parser";
import GetLink from '../functions/GetLink';
import './BookValuationModule.scss'
import PlayVideo from '../PlayVideo/PlayVideo';
function BookValuationModule() {

    const data = useStaticQuery(graphql`
    query ValuationModule {
      glstrapi {
        globalConfig {
            Book_Valuation_Module {
              Module_Title
              Module_Content
              Module_Image {
                alternativeText
                url
              }
              Module_CTA {
                CTA_Label
                CTA_Secondary_Link
                CTA_Link {
                  Primary_URL
                }
              }
              Video_URL
            }
          }
      }
    }
  `)
  const moduledata = data?.glstrapi?.globalConfig?.Book_Valuation_Module

  const [isPlay, setPlay] = useState(false);

  return (
    <section className="book-valuation-wrap section-p120 grey-bg">
        <Container>
            <Row className='align-items-center'>
                <Col md={12} xl={6} className='img-wrapper'>
                  <img src={moduledata?.Module_Image?.url}/>
                  {moduledata?.Video_URL && (
                    <a onClick={(e) => { setPlay(true);}}><i className="icon icon-play"></i></a>
                  )}
                </Col>
                <Col xl={1}></Col>
                <Col xl={5} md={12} className="module-content">
                    <h4>{moduledata?.Module_Title}</h4>
                    {parse(moduledata?.Module_Content)}
                    {moduledata.Module_CTA && (
              <div className="banner-btns">
                {moduledata.Module_CTA.map((item, index) => {
                  if (item.CTA_Secondary_Link) {
                    return (
                      <Link to={item.CTA_Secondary_Link} className={`btn btn-banner`}>
                        {item.CTA_Label}
                      </Link>
                    );
                  }
                  return (
                    <GetLink
                      className={`btn btn-banner`}
                      label={item.CTA_Label}
                      link={item.CTA_Link}
                    />
                  );
                })}
              </div>
            )}
                </Col>
            </Row>
        </Container>
        {isPlay && (
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setPlay}
            videoId=""
            isCloseFunction={setPlay}
            videourl={moduledata?.Video_URL}
            htmlink={""}
          />
        )}
    </section>
  );
}

export default BookValuationModule;
