import React from "react"
import { Container } from "react-bootstrap"
import StatCard from "./StatCard"
import "./StatsListing.scss"

const StatsListingModule = ({ module }) => {
  let theme = module?.Stats_Theme ? `${module?.Stats_Theme}` : 'section-m'

  return (
    <div className={`${theme} stats-wrapper`}>
      <Container className="stats-container">
        <h2 className="heading">{module.Stats_Heading}</h2>
        {module.Stats_Description && (
          <p className="description">{module.Stats_Description}</p>
        )}
        <div
          className={`stats-section ${
            module?.Stats_List?.length < 4 ? "stats-section-alt" : ""
          }`}
        >
          {module?.Stats_List.map(statItem => (
            <StatCard statItem={statItem} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default StatsListingModule
