import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import "./assets/styles/_index.scss";
import parse from "html-react-parser";
import GetLink from "../functions/GetLink";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import PlayVideo from "../PlayVideo/PlayVideo";

const TileBlock = ({ title, heading, content, ctas, image, imgPosition, videoLink, Video_URL }) => {
  const [isPlay, setPlay] = useState(false);
  const [videourl, setVideourl] = useState(false);

  return (
    <section className="tile-block-wrapper section-m">
      <Container>
        <Row
          className={`tile-block-container ${imgPosition === "Left" ? "container-reverse" : ""}`}
        >
          <Col lg={5} className="order-lg-1 order-2">
            <div className="tile-block-details-wrapper text-center">
              <h4>{title}</h4>
              {heading && <h2>{heading}</h2>}
              {content && <div className="tile-block-text">{parse(content)}</div>}
              {ctas?.length > 0 && (
                <div className="d-flex align-items-center justify-content-center tile-block-link-wrapper">
                  {ctas.map((cta) => (
                    <>
                      {cta.CTA_Secondary_Link ? (
                        <a
                          href={cta.CTA_Secondary_Link}
                          // target="_blank"
                          className="tile-block-link"
                        >
                          {cta.CTA_Label}
                        </a>
                      ) : (
                        <GetLink
                          label={cta.CTA_Label}
                          link={cta.CTA_Link}
                          className="tile-block-link"
                        />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          </Col>
          <Col lg={1} className="order-lg-2 d-lg-block d-none"></Col>
          <Col lg={6} className="order-lg-3 order-1">
            <div className="tile-img-wrapper">
              {image && (
                // <GetGatsbyImage
                //   image={image}
                //   fallbackalt={image.alternativeText + "- block module image"}
                //   className="img-fluid"
                // />
                <img src={image.url} alt={image.alternativeText + "- block module image"}></img>
              )}
              {(videoLink || Video_URL) && (
                <a onClick={(e) => {setVideourl(videoLink ? videoLink : Video_URL); setPlay(true);}}><i className="icon icon-play"></i></a>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {isPlay && videourl && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={videourl}
          htmlink={""}
        />
      )}
    </section>
  );
};

export default TileBlock;
