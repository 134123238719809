import React, { useEffect, useState } from "react";
import GetLink from "../functions/GetLink";
import parse from "html-react-parser";
import "./Banner.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
// import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import ReviewSection from "../ReviewSection/ReviewSection";

function Banner(props) {
  const { videos, layout, title } = props;
  let processedImages = JSON.stringify({});
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms;
  }

  const [video, setVideo] = useState(null);
  useEffect(() => {
    if (videos && videos.length > 0) {
      setVideo(videos[Math.floor(Math.random() * videos.length)]);
    }
  }, [videos]);

  const[showContent, setShowContent] = useState(false);

  // show content 2.5s after video has loaded
  useEffect(() => {
    if (video) {
      setTimeout(() => {
          setShowContent(true);
      }, 2500);
    }
  }, [video]);
  const pageHasTitle = layout === "Culture_Landing";

  return (
    <React.Fragment>
      {props.image && (
        <div className={`banner ${props.title == "Home" ? "home-banner" : ""}`}>
        {props.Breadcrumbs && props.Breadcrumbs}

          <div className="banner-img-container">
            {props.title == "Home" ? (
              video?.url && (
              <video
                controls={false}
                muted={"muted"}
                playsinline={"playsinline"}
                autoPlay={"autoplay"}
                preLoad="auto"
                loop
              >
                <source src={video.url} type="video/mp4" />
              </video>
              )
            ) : (
            <GetGGFXImage
              imagename={"articles.Banner_Image.bannerimg"}
              imagesource={props.image}
              fallbackalt={props.metattitle ? props.metattitle : props.bannertitle}
              imagetransformresult={processedImages}
              id={props.id}
            />)}
            <div className="banner-overlay" />
          </div>
          <Container className={"banner-content-wrapper" + (
            props.title == "Home" 
            ? (showContent ? " banner-content__show" : "") 
            : " banner-content__show"
            )}>
            <div className="banner-content">
            {pageHasTitle && <h4 className="title">{title}</h4>}
              {props.description && props.title == "Home" && parse(props.description)}
              <h1>{props.bannertitle}</h1>
              {props.description && props.title != "Home" && parse(props.description)}
            </div>
            {props.cta && (
              <div className="banner-btns">
                {props.cta.map((item, index) => {
                  if (item.CTA_Secondary_Link) {
                    return (
                      <Link to={item.CTA_Secondary_Link} className={`btn btn-banner`}>
                        {item.CTA_Label}
                      </Link>
                    );
                  }
                  return (
                    <GetLink
                      className={`btn btn-banner`}
                      label={item.CTA_Label}
                      link={item.CTA_Link}
                    />
                  );
                })}
              </div>
            )}
            {props.reviews == true && (
              <div className="reviews__google">
                <ReviewSection />
              </div>
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}

export default Banner;
