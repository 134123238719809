import React from "react"
import GetLink from "../functions/GetLink"
import CountUp from 'react-countup';

const StatCard = ({ statItem }) => {
  if (!statItem) return null
  let isNan = isNaN(statItem.Stats_Value.replace(/,/g, ""))

  const statValue = isNan ? statItem.Stats_Value : parseFloat(statItem.Stats_Value.toString().replace(/,/g, ""))
  const decimals = statValue.toString().split('.')[1] ? statValue.toString().split('.')[1].length : 0

  return (
    <div className="stat-item-container">
      <i className={`icon-${statItem.Stats_Icon_Class}`}></i>
      <div className="content">

        <p className={`stat ${statItem.Stats_Prefix ? "" : "stat-sm"}`}>
          {!isNan ? 
          <>
          {(statItem.Stats_Prefix ? statItem.Stats_Prefix : "") }
            <CountUp duration={5} decimals={decimals} separator=',' start={0} end={statValue} />
          {(statItem.Stats_Postfix ? statItem.Stats_Postfix : "")}
          </> : <span>{statItem.Stats_Value}</span>
          }
          {/*           
          {(statItem.Stats_Prefix ? statItem.Stats_Prefix : "") +
            statItem.Stats_Value +
            (statItem.Stats_Postfix ? statItem.Stats_Postfix : "")} */}
        </p>
        <p className="text">{statItem.Stats_Caption}</p>
      </div>
      {statItem.Stats_Link ? (
        <GetLink
          link={statItem.Stats_Link}
          label={statItem.Stats_Link_Label}
          className="btn btn-dark"
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default StatCard
